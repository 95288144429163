<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <h1>
                    {{ $t('eav.entity_type') }}
                </h1>
                <v-form>
                    <ValidationObserver v-slot="{ invalid, validated, passes, validate }">
                        <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="slug">
                            <TextInput
                                id="slug"
                                v-model="slug"
                                :label="$t('eav.slug')"
                                :error="!valid"
                                :error-messages="errors"
                            ></TextInput>
                        </ValidationProvider>

                        <template v-for="lng in listLanguages">
                            <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="name[lng]">
                                <TextInput
                                    :id="`name_${lng}`"
                                    :label="$t(`name_${lng}`)"
                                    :error="!valid"
                                    :error-messages="errors"
                                    v-model="name[lng]"
                                    require
                                ></TextInput>
                            </ValidationProvider>
                        </template>

                        <v-checkbox v-model="forModel" :label="$t('eav.is_for_model')"/>
                        <template v-if="forModel">
                            <TextInput
                                id="model"
                                :label="$t('eav.model')"
                                :hint="$t('eav.model_desc')"
                                v-model="model"
                            ></TextInput>

                            <TextInput
                                :label="$t('eav.primary')"
                                :hint="$t('eav.primary_desc')"
                                id="primary"
                                class="mb-4"
                                v-model="primary"
                            ></TextInput>
                        </template>
                    </ValidationObserver>
                    <Btn @click="postItem">{{ $t('create') }}</Btn>
                </v-form>
            </div>
        </div>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex"
import {mask} from 'vue-the-mask'
import SettingMenu from "../components/SettingMenu";
import TextInput from "../components/Form/TextInput";
import Btn from "@/components/Form/Btn.vue";
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    name: "Pages",
    directives: {
        mask,
    },
    components: {
        Btn,
        SettingMenu,
        TextInput,
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            slug: '',
            model: '',
            forModel: false,
            primary: '',
            name: {},
            errors: {},
        };
    },
    computed: {
        ...mapGetters(['lang', 'listLanguages']),
    },
    mounted() {
    },
    methods: {
        postItem() {
            this.loading = true;
            let data = {
                slug: this.slug,
                model: this.model,
                primary: this.primary,
            };

            this.listLanguages.forEach((lng) => {
                if (!data[lng]) {
                    data[lng] = {};
                }
                data[lng]['name'] = this.name[lng];
            })

            this.$http.post('admin/eav', data)
                .then(response => {
                    this.items = response.data.data;
                    this.totalCountries = response.data.total;

                    this.$router.push({name: 'eav.entity_type'});
                })
                .catch(errorResp => {
                    this.errors = errorResp.body.errors;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
</script>
